import PropTypes from 'prop-types';
import React from 'react';
import { useUser } from 'reactfire';

import Loading from '../Loading';

function WaitForInitialization({ showLoading, children }) {
  const { status } = useUser();
  if (status !== 'success') {
    return showLoading ? <Loading /> : null;
  }
  return children;
}

WaitForInitialization.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  showLoading: PropTypes.bool,
};

WaitForInitialization.defaultProps = {
  showLoading: false,
};

export default WaitForInitialization;
