import './consent.css';

import {
  ConsentManager, loadPreferences, onPreferencesSaved, openConsentManager,
} from '@segment/consent-manager';
import inRegions from '@segment/in-regions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const cookieButton = document.getElementById('pf-cookie-button');
if (cookieButton) { cookieButton.onclick = openConsentManager; }

const bannerContent = (
  <span>
    Parallel Fluidics website stores cookies on your computer. These cookies are used to collect
    information about how you interact with our website and allow us to remember you. We use this
    information in order to improve and customize your browsing experience and for analytics and
    metrics about our visitors both on this website and other media. To find out more about the
    cookies we use, see our
    {' '}
    <a
      href="https://www.parallelfluidics.com/legal/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
    .
  </span>
);
const bannerSubContent = 'You can manage your preferences here!';
const preferencesDialogTitle = 'Website Data Collection Preferences';
const preferencesDialogContent = (
  <div>
    <p>
      Parallel Fluidics uses data collected by cookies and JavaScript libraries to improve your
      browsing experience, analyze site traffic, deliver personalized advertisements, and increase
      the overall performance of our site.
    </p>
    <p>
      By using our website, you’re agreeing to our
      {' '}
      <a
        href="https://www.parallelfluidics.com/legal/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Website Data Collection Policy
      </a>
      .
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a category of data
      collection, select “No” and save your preferences.
    </p>
  </div>
);
const cancelDialogTitle = 'Are you sure you want to cancel?';
const cancelDialogContent = (
  <div>
    Your preferences have not been saved. By continuing to use our website, you’re agreeing to our
    {' '}
    <a
      href="https://www.parallelfluidics.com/legal/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Website Data Collection Policy
    </a>
    .
  </div>
);

function ConsentBanner({ writeKey }) {
  const [prefs, updatePrefs] = useState(loadPreferences());

  window.prefs = prefs;

  const cleanup = onPreferencesSaved((preferences) => {
    updatePrefs(preferences);
  });

  React.useEffect(() => () => {
    cleanup();
  });

  const inCA = inRegions(['CA']);
  const inEU = inRegions(['EU']);
  const shouldRequireConsent = inRegions(['CA', 'EU']);
  const caDefaultPreferences = {
    advertising: false,
    marketingAndAnalytics: true,
    functional: true,
  };
  const euDefaultPreferences = {
    advertising: false,
    marketingAndAnalytics: false,
    functional: false,
  };

  let closeBehavior;
  if (inCA()) {
    closeBehavior = () => caDefaultPreferences;
  } else if (inEU()) {
    closeBehavior = 'deny';
  } else {
    closeBehavior = 'accept';
  }

  let initialPreferences;
  if (inCA()) {
    initialPreferences = caDefaultPreferences;
  } else if (inEU()) {
    initialPreferences = euDefaultPreferences;
  } else {
    initialPreferences = undefined;
  }

  return (
    <ConsentManager
      writeKey={writeKey}
      bannerContent={bannerContent}
      bannerSubContent={bannerSubContent}
      preferencesDialogTitle={preferencesDialogTitle}
      preferencesDialogContent={preferencesDialogContent}
      cancelDialogTitle={cancelDialogTitle}
      cancelDialogContent={cancelDialogContent}
      closeBehavior={closeBehavior}
      shouldRequireConsent={shouldRequireConsent}
      initialPreferences={initialPreferences}
    />
  );
}

ConsentBanner.propTypes = {
  writeKey: PropTypes.string.isRequired,
};

export default ConsentBanner;
