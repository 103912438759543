import './flowcell.css';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import * as Comlink from 'comlink';
import { getApp } from 'firebase/app';
import { fetchSignInMethodsForEmail, getAuth, signInAnonymously } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useUser } from 'reactfire';
import * as Yup from 'yup';

import analytics from '../../app/analytics';
import Button from '../form/button';
import Select from '../form/select';
import { CorsWorker as Worker } from '../UploadPart/cors-worker';
import LinearProgressWithLabel from './progress';
import Survey from './survey';
import Viewer from './viewer';

function UploadConfirmationModal({
  open, onClose: handleClose, fileID, parameters,
}) {
  const initialValues = { email: '' };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address'),
    selfReportedChannel: Yup.string().required('Required'),
  });

  const { data: user } = useUser();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      enableReinitialize
      onSubmit={async (values, { setFieldError }) => {
        try {
          if (!fileID || !user || !user.uid || !parameters) {
            setFieldError('email', 'Something went wrong. Please refresh the page and try again.');
            return;
          }

          if (!user.email) {
            const methods = await fetchSignInMethodsForEmail(
              getAuth(),
              values.email,
            );
            if (methods.length > 0) {
              setFieldError('email', 'Email is already in use. Use a different email or log in to upload.');
              return;
            }
          }

          const functions = getFunctions(getApp());
          const addFlowCell = httpsCallable(functions, 'addFlowCell');
          await addFlowCell({
            fileID,
            email: !user.email ? values.email : undefined,
            parameters,
            selfReportedChannel: values.selfReportedChannel,
          });
          analytics.requestFlowcellQuote({
            userId_: user.uid,
            email: values.email,
          });

          // give analtyics 300ms to log before forwarding to /upload-confirmation or /parts page
          setTimeout(() => {
            if (user.email) {
              window.location.href = '/parts';
            } else {
              window.location.href = '/upload-confirmation';
            }
          }, 300);
        } catch (err) {
          setFieldError('email', err.message);
        }
      }}
    >
      {({
        isSubmitting,
        handleBlur,
        values,
        errors,
        handleSubmit,
        handleChange,
      }) => (
        <Modal open={open} onClose={handleClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'rgb(31, 42, 55)',
            boxShadow: 24,
            p: 4,
          }}
          >
            <Typography variant="h4" component="h2" sx={{ mb: 2, fontFamily: 'Industry', fontWeight: 600 }}>
              One more thing...
            </Typography>
            {!user?.email ? (
              <>
                <FormLabel
                  sx={{
                    color: 'text.main',
                    '&.Mui-focused': {
                      color: 'text.main',
                    },
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Please enter your email address.
                </FormLabel>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <TextField
                    id="email"
                    value={values.email}
                    size="small"
                    inputProps={{ type: 'email' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </FormControl>
              </>
            ) : null}
            <FormLabel
              sx={{
                color: 'text.main',
                '&.Mui-focused': {
                  color: 'text.main',
                },
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              How did you hear about us?
            </FormLabel>
            <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
              <TextField
                id="selfReportedChannel"
                value={values.selfReportedChannel}
                size="small"
                inputProps={{ type: 'email' }}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  color: 'text.main',
                  '& input:disabled': {
                    color: 'text.main',
                  },
                  '& .Mui-disabled::after': {
                    border: 0,
                  },
                }}
                error={!!errors.selfReportedChannel}
                helperText={errors.selfReportedChannel}
              />
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Button
                onClick={handleSubmit}
                label={isSubmitting ? 'Please wait...' : 'Submit'}
                disabled={
                  (!user?.email && (!values.email || !!errors.email))
                  || !values.selfReportedChannel || !!errors.selfReportedChannel
                  || isSubmitting
                }
              />
            </div>
          </Box>
        </Modal>
      )}
    </Formik>
  );
}

UploadConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  fileID: PropTypes.string,
  parameters: PropTypes.shape({
    portToPortDistance: PropTypes.number.isRequired,
    channelWidth: PropTypes.number.isRequired,
    channelDepth: PropTypes.number.isRequired,
    taperAngle: PropTypes.number.isRequired,
    cornerRadius: PropTypes.number.isRequired,
    viaDiameter: PropTypes.number.isRequired,
    component: PropTypes.string.isRequired,
  }),
};

UploadConfirmationModal.defaultProps = {
  open: false,
  fileID: null,
  parameters: null,
};

function FlowCell() {
  const worker = useRef(null);
  const [meshes, setMeshes] = useState();
  const [stepURL, setSTEPURL] = useState();
  const [isReady, setIsReady] = useState(false);
  const [isGenerating, setIsGenerating] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState({});
  const openModal = (text) => {
    setModalText(text);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalText({});
    setModalOpen(false);
  };

  const [error, setError] = useState();
  const [renderError, setRenderError] = useState({});
  const [initialValues, setInitialValues] = useState({
    portToPortDistance: '50',
    channelWidth: '5',
    channelDepth: '0.5',
    taperAngle: '90',
    cornerRadius: '1.0',
    viaDiameter: '0.5',
    component: '1428',
  });

  const [submitting, setSubmitting] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [modalFileID, setModalFileID] = useState();
  const openEmailModal = (fileID) => {
    setEmailModalOpen(true);
    setModalFileID(fileID);
  };
  const closeEmailModal = () => {
    setEmailModalOpen(false);
    setSubmitting(false);
    setModalFileID(null);
    setUploadProgress(0);
    setIsUploading(false);
  };

  const { data: user } = useUser();

  const updatePreview = (data) => {
    setMeshes(data.meshes);
    setSTEPURL(data.step);
    setIsGenerating(false);
  };

  // set up default preview
  useEffect(() => {
    const url = new URL('./default.json', import.meta.url);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        updatePreview({ meshes: data });
      });
  }, []);

  const [progressDict, setProgressDict] = useState({});
  const progress = useMemo(() => {
    const total = Object.values(progressDict).reduce((acc, v) => acc + v.total, 0);
    const loaded = Object.values(progressDict).reduce((acc, v) => acc + v.loaded, 0);
    return (100.0 * loaded) / total;
  });

  useEffect(() => {
    (async () => {
      const corsWorker = new Worker(new URL('./openCascadeWorker.js', import.meta.url));
      const FlowCellWorker = Comlink.wrap(corsWorker.getWorker());
      worker.current = await new FlowCellWorker();
      worker.current.init(
        Comlink.proxy(({ loaded, total, file }) => {
          setProgressDict((old) => ({ ...old, [file]: { loaded, total } }));
        }),
        Comlink.proxy(() => { setIsReady(true); }),
      );
    })();
    return () => {
      if (worker.current) {
        worker.current.terminate();
        worker.current = null;
      }
      setIsReady(false);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    portToPortDistance: Yup.number().required('Port-to-port distance is required').min(10, 'Port-to-port distance must be at least 10mm').max(65, 'Port-to-port distance can be at most 65mm'),
    channelWidth: Yup.number().required('Channel width is required').max(20, 'Channel width can be at most 20mm'),
    channelDepth: Yup.number().required('Channel depth is required').min(0.05, 'Channel depth must be at least 0.05mm').max(0.75, 'Channel depth can be at most 0.75mm'),
    taperAngle: Yup.number().required('Taper angle is required').max(170, 'Taper angle can be at most 170°'),
    cornerRadius: Yup.number().required('Corner radius is required').min(0.25, 'Corner radius must be at least 0.25mm'),
    email: Yup.string().email('Invalid email address'),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        initialErrors={renderError}
        validationSchema={validationSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={async (values, { setFieldError }) => {
          analytics.clickCta({
            cta: 'Regenerate CAD',
            url: window.location.href.replace(window.location.origin, ''),
          });
          setError(null);
          setRenderError({});
          if (isReady) {
            const portToPortDistance = parseFloat(values.portToPortDistance);
            const channelWidth = parseFloat(values.channelWidth);
            const channelDepth = parseFloat(values.channelDepth);
            const taperAngle = parseFloat(values.taperAngle);
            const cornerRadius = parseFloat(values.cornerRadius);
            const viaDiameter = parseFloat(values.viaDiameter);
            const { component } = values;

            if (channelWidth < Math.max(0.75, viaDiameter + 0.25)) {
              setFieldError('channelWidth', 'Channel width must be the greater than 0.75mm or the via diameter plus 0.25mm (whichever is greater)');
              setIsGenerating(false);
              return;
            }

            if (cornerRadius > channelWidth / 2) {
              setFieldError('cornerRadius', 'Corner radius must be less than half the channel width');
              setIsGenerating(false);
              return;
            }

            if (component === 'none') {
              if (viaDiameter < 0.5 || viaDiameter > channelWidth - 0.25) {
                setFieldError('viaDiameter', 'Via diameter must be between 0.5mm and the channel width minus 0.25mm');
                setIsGenerating(false);
                return;
              }
            } else if (viaDiameter < 0.5 || viaDiameter > 1.0) {
              setFieldError('viaDiameter', 'Via diameter must be between 0.5mm and 1.0mm for connectorized devices');
              setIsGenerating(false);
              return;
            }

            setMeshes(null);
            setIsGenerating(true);
            setInitialValues({ ...values });
            worker.current.generate({
              portToPortDistance,
              channelWidth,
              channelDepth,
              taperAngle,
              cornerRadius,
              viaDiameter,
              component,
            }, Comlink.proxy(updatePreview));
          }
        }}
      >
        {({
          values,
          errors,
          dirty,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <>
            <UploadConfirmationModal
              open={emailModalOpen}
              onClose={closeEmailModal}
              fileID={modalFileID}
              parameters={{
                portToPortDistance: parseFloat(values.portToPortDistance),
                channelWidth: parseFloat(values.channelWidth),
                channelDepth: parseFloat(values.channelDepth),
                taperAngle: parseFloat(values.taperAngle),
                cornerRadius: parseFloat(values.cornerRadius),
                viaDiameter: parseFloat(values.viaDiameter),
                component: values.component,
              }}
            />
            <Modal
              open={modalOpen}
              onClose={closeModal}
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'rgb(31, 42, 55)',
                boxShadow: 24,
                p: 4,
              }}
              >
                <Typography variant="h6" component="h2" sx={{ mb: 1, fontFamily: 'Industry' }}>
                  {modalText.title}
                </Typography>
                <p dangerouslySetInnerHTML={{ __html: modalText.body }} />
                {modalText.image ? (
                  <img src={modalText.image} alt="" style={{ width: '100%' }} />
                ) : null}
              </Box>
            </Modal>
            {error ? (
              <div className="error w-dyn-empty margin-top">
                <div>
                  {error}
                </div>
              </div>
            ) : null}
            <div className="designer-container">
              <div className="form-controls">
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <FormLabel
                    sx={{
                      color: 'text.main',
                      '&.Mui-focused': {
                        color: 'text.main',
                      },
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Port-to-Port Distance (mm)
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => openModal({
                        title: 'Port-to-Port Distance',
                        body: 'Determines the distance between the center of the inlet and outlet ports.',
                        image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64cd4f9e5fe2b9f0a476c63f_port-to-port.png',
                      })}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <TextField
                    id="portToPortDistance"
                    value={values.portToPortDistance}
                    disabled={isGenerating || submitting}
                    size="small"
                    inputProps={{
                      step: 1,
                      min: 10,
                      max: 65,
                      type: 'number',
                      'aria-labelledby': 'quantity-slider',
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.portToPortDistance}
                    helperText={errors.portToPortDistance}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <FormLabel
                    sx={{
                      color: 'text.main',
                      '&.Mui-focused': {
                        color: 'text.main',
                      },
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Channel Width (mm)
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => openModal({
                        title: 'Channel Width',
                        body: 'Determines the width of the channel in cross-section.',
                        image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64d42fba549f56d761430775_channel-width.png',
                      })}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <TextField
                    id="channelWidth"
                    value={values.channelWidth}
                    disabled={isGenerating || submitting}
                    size="small"
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 20,
                      type: 'number',
                      'aria-labelledby': 'quantity-slider',
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.channelWidth}
                    helperText={errors.channelWidth}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <FormLabel
                    sx={{
                      color: 'text.main',
                      '&.Mui-focused': {
                        color: 'text.main',
                      },
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Channel Depth (mm)
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => openModal({
                        title: 'Channel Depth',
                        body: 'Determines the depth of the channel in cross-section. All channel walls will include two degrees of draft.',
                        image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64d42fb9f09e4c0aa5d04486_channel-depth.png',
                      })}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <TextField
                    id="channelDepth"
                    value={values.channelDepth}
                    disabled={isGenerating || submitting}
                    size="small"
                    inputProps={{
                      step: 0.05,
                      min: 0.05,
                      max: 0.75,
                      type: 'number',
                      'aria-labelledby': 'quantity-slider',
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.channelDepth}
                    helperText={errors.channelDepth}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <FormLabel
                    sx={{
                      color: 'text.main',
                      '&.Mui-focused': {
                        color: 'text.main',
                      },
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Taper Angle
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => openModal({
                        title: 'Taper Angle',
                        body: 'The angle of expansion from the inlet via to the straight channel section.',
                        image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64d42fba66f891964c2e0362_taper-angle.png',
                      })}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <TextField
                    id="taperAngle"
                    value={values.taperAngle}
                    disabled={isGenerating || submitting}
                    size="small"
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 170,
                      type: 'number',
                      'aria-labelledby': 'quantity-slider',
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.taperAngle}
                    helperText={errors.taperAngle}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <FormLabel
                    sx={{
                      color: 'text.main',
                      '&.Mui-focused': {
                        color: 'text.main',
                      },
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Corner Radius (mm)
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => openModal({
                        title: 'Corner Radius',
                        body: 'Determines the radius where two channel walls meet.',
                        image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64d42fba51cab34a696804e0_corner-radius.png',
                      })}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <TextField
                    id="cornerRadius"
                    value={values.cornerRadius}
                    disabled={isGenerating || submitting}
                    size="small"
                    inputProps={{
                      step: 0.25,
                      min: 0.25,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'quantity-slider',
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.cornerRadius}
                    helperText={errors.cornerRadius}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <FormLabel
                    sx={{
                      color: 'text.main',
                      '&.Mui-focused': {
                        color: 'text.main',
                      },
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Via Diameter (mm)
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => openModal({
                        title: 'Via Diameter',
                        body: 'Determines the diameter of the vias (otherwise known as through-holes) that connect the flow cell channel to the top surface of the device. The via diameter must be 0.5 mm - 1.5mm for compatibility with our integrated components.',
                        image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64d42fba4eefd6c69216dd88_via-diameter.png',
                      })}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <TextField
                    id="viaDiameter"
                    value={values.viaDiameter}
                    disabled={isGenerating || submitting}
                    size="small"
                    inputProps={{
                      step: 0.25,
                      min: 0.5,
                      max: 20,
                      type: 'number',
                      'aria-labelledby': 'quantity-slider',
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      color: 'text.main',
                      '& input:disabled': {
                        color: 'text.main',
                      },
                      '& .Mui-disabled::after': {
                        border: 0,
                      },
                    }}
                    error={!!errors.viaDiameter}
                    helperText={errors.viaDiameter}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem', flex: '0 1 auto' }}>
                  <Select
                    name="component"
                    value={values.component}
                    disabled={isGenerating || submitting}
                    label={(
                      <FormLabel
                        sx={{
                          color: 'text.main',
                          '&.Mui-focused': {
                            color: 'text.main',
                          },
                        }}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        Components
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => openModal({
                            title: 'Components',
                            body: ('<ul>'
                            + '<li><a href="https://www.parallelfluidics.com/components/luer-port" target="_blank">Luer lock</a> ports are compatible with off-the-shelf luer lock fittings and soft tubing.</li>'
                            + '<li><a href="https://www.parallelfluidics.com/components/1-4-28-port" target="_blank">¼-28 ports</a> are compatible with ¼-28 flat bottom fittings and rigid capillary tubing.</li>'
                            + '<li><a href="https://www.parallelfluidics.com/components/250-ul-reservoir" target="_blank">250 uL reservoirs</a> can be used to create gravity-driven flow with a rocker table.</li>'
                            + '</ul>'
                            ),
                            image: 'https://uploads-ssl.webflow.com/628e9b9424978884722a46cb/64d42fba8e8082fd7c35817a_components.png',
                          })}
                        >
                          <QuestionMarkIcon fontSize="small" />
                        </IconButton>
                      </FormLabel>
                    )}
                    setFieldValue={setFieldValue}
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="1428">¼-28 ports</MenuItem>
                    <MenuItem value="luer">Luer-lock ports</MenuItem>
                    <MenuItem value="reservoir">250 µL reservoirs</MenuItem>
                  </Select>
                </FormControl>
                {isReady ? null : (
                  <Box sx={{ flexDirection: 'column', mt: 1, mb: 3 }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                )}
                <div className="button-container">
                  <Button
                    onClick={handleSubmit}
                    disabled={isGenerating || !isReady || submitting}
                    label={{
                      true: {
                        true: 'Generating CAD...',
                        false: 'Regenerate CAD',
                      }[isGenerating],
                      false: 'Finishing up. Please wait...',
                    }[isReady]}
                    style={{ marginRight: '2rem' }}
                  />
                  <Button
                    onClick={async () => {
                      analytics.clickCta({
                        cta: 'Get a quote',
                        url: window.location.href.replace(window.location.origin, ''),
                      });
                      setSubmitting(true);
                      setIsUploading(true);
                      setUploadProgress(10);

                      // create an anonymous user if one isn't already signed in
                      let userID = user?.uid;
                      if (!userID) {
                        const { user: anonymousUser } = await signInAnonymously(getAuth());
                        userID = anonymousUser.uid;
                      }
                      setUploadProgress(25);

                      // upload file metadata, then file
                      const filename = 'flowcell.step';
                      const functions = getFunctions(getApp());
                      const { data } = await httpsCallable(functions, 'uploadFileMetadata')({ filename });
                      const { fileID } = data;
                      const storageRef = ref(getStorage(), `user-uploads/cad/${userID}/${fileID}.step`);
                      const step = await fetch(stepURL).then((r) => r.blob());
                      setUploadProgress(50);

                      const uploadTask = uploadBytesResumable(storageRef, step);
                      uploadTask.on(
                        'state_changed',

                        // Progress
                        (snapshot) => {
                          setUploadProgress(
                            Math.min(
                              50 + (snapshot.bytesTransferred / snapshot.totalBytes) * 50,
                              95,
                            ),
                          );
                        },

                        // Error
                        (err) => {
                          setUploadProgress(0);
                          setIsUploading(false);
                          setError(err.message);
                          setSubmitting(false);
                        },

                        // Success
                        () => { openEmailModal(fileID); },
                      );
                    }}
                    label={submitting ? 'Please wait...' : 'Get a quote'}
                    disabled={(
                    !isReady || isGenerating || submitting || !stepURL
                    || dirty || Object.keys(renderError).length > 0
                  )}
                    style={{ margin: '0 auto' }}
                  />
                </div>
              </div>
              <div className="preview">
                {meshes && !isUploading ? <Viewer meshes={meshes} /> : null}
                {isGenerating ? <CircularProgress /> : null}
                {isUploading ? (
                  <Box sx={{
                    flexDirection: 'row', mt: 1, mb: 3, width: '50%',
                  }}
                  >
                    <p>Uploading...</p>
                    <LinearProgressWithLabel value={uploadProgress} />
                  </Box>
                ) : null}
              </div>
            </div>
          </>
        )}
      </Formik>
      <Survey />
    </>
  );
}

export default FlowCell;
