/* eslint-disable import/prefer-default-export */
// https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272
export class CorsWorker {
  worker;

  constructor(url) {
    const objectURL = URL.createObjectURL(
      new Blob([`importScripts(${JSON.stringify(url.toString())});`], {
        type: 'application/javascript',
      }),
    );
    this.worker = new Worker(objectURL);
    URL.revokeObjectURL(objectURL);
  }

  getWorker() {
    return this.worker;
  }
}
