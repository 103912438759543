import { Sidetab } from '@typeform/embed-react';
import React, { useEffect, useRef } from 'react';

function Survey() {
  const formID = 'VgXfkA5j';
  const formRef = useRef(null);
  useEffect(() => {
    if (formRef.current) {
      const open = () => {
        if (formRef.current) {
          formRef.current.open();
        }
      };
      const typeformElements = document.querySelectorAll('[pf-typeform-link="true"] a');
      typeformElements.forEach((element) => {
        element.addEventListener('click', open);
      });
      return () => {
        typeformElements.forEach((element) => {
          element.removeEventListener('click', open);
        });
      };
    }
    return () => {};
  }, [formRef.current]);

  return (
    <Sidetab
      ref={formRef}
      id={formID}
      buttonText="Vote for the next design"
      buttonColor="#4fafc3"
      autoClose={2000}
      height={window.innerHeight}
      hideFooter
    />
  );
}

export default Survey;
