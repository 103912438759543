import React from 'react';

import { ReactComponent } from './loader_three-dots-white.svg';

function Loading() {
  return (
    <div style={{
      display: 'flex',
      width: '100%',
      WebkitBoxOrient: 'vertical',
      WebkitBoxDirection: 'normal',
      WebkitFlexDirection: 'column',
      msFlexDirection: 'column',
      flexDirection: 'column',
      WebkitBoxPack: 'center',
      WebkitJustifyContent: 'center',
      msFlexPack: 'center',
      justifyContent: 'center',
      WebkitBoxAlign: 'center',
      WebkitAlignItems: 'center',
      msFlexAlign: 'center',
      alignItems: 'center',
    }}
    >
      <ReactComponent className="preloader-image" />
    </div>
  );
}

export default Loading;
