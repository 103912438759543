import { AvoInspector } from 'avo-inspector';
import { useEffect, useState } from 'react';

import Avo from './Avo';
import ConsentBanner from './consent';
import segment from './segment';

const ANALYTICS_VERSION = '1.0.0';

const initAnalytics = () => {
  const env = (process.env.NODE_ENV === 'development' || window.location.href.includes('webflow.io')) ? 'dev' : 'prod';

  const inspector = new AvoInspector({
    env,
    apiKey: Avo.avoInspectorApiKey,
    version: ANALYTICS_VERSION,
  });

  let segmentWriteKey;
  const setSegmentWriteKey = (key) => { segmentWriteKey = key; };
  Avo.initAvo(
    { env, inspector },
    null, // systemProperties
    null, // destinationOptions
    segment(setSegmentWriteKey),
  );
  return segmentWriteKey;
};

const useSegmentEmail = () => {
  const [email, setEmail] = useState();
  useEffect(() => {
    window.analytics.ready(() => {
      setEmail(window.analytics.user().traits().email);
      window.analytics.on('identify', (userId, properties) => { setEmail(properties.email); });
    });
  }, []);
  return email;
};

const logLIConverstion = (conversionId) => {
  try {
    if (!window.lintrk) {
      window.lintrk = (a, b) => { window.lintrk.q.push([a, b]); };
      window.lintrk.q = [];
    }
    window.lintrk('track', { conversion_id: conversionId });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

export default Avo;

export {
  ConsentBanner, initAnalytics, useSegmentEmail, logLIConverstion,
};
