import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useUser } from 'reactfire';

function EnsureUnAuth({ children }) {
  const { status, data: user } = useUser();

  useEffect(() => {
    if (status === 'loading') return;
    if (user && user?.email) {
      window.location.href = '/';
    }
  }, [status, user]);

  return children;
}

EnsureUnAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default EnsureUnAuth;
