// Adapted from https://github.com/avohq/destination-interface-code-snippets/blob/main/javascript/web/segment.js

const segmentDestinationInterface = (setAPIKey) => ({
  make: (_env, apiKey) => {
    setAPIKey(apiKey);
  },

  identify: (userId) => window.analytics.identify(userId),

  logEvent: (eventName, eventProperties) => window.analytics.track(eventName, eventProperties),

  setUserProperties: (_, userProperties) => window.analytics.identify(userProperties),

  unidentify: () => window.analytics.reset(),

  page: (eventName, eventProperties) => window.analytics.page(eventName, eventProperties),

  revenue: (amount, eventProperties) => window.analytics.track('Purchase Complete', {
    ...eventProperties,
    revenue: amount,
  }),

  // The following methods are used for group analytics and are not required. Learn more about group analytics here: https://www.avo.app/docs/data-design/groups
  setGroupProperties: (
    _groupType,
    groupId,
    groupProperties,
  ) => window.analytics.group(groupId, groupProperties),

  addCurrentUserToGroup: (_groupType, groupId) => window.analytics.group(groupId),

  // Not supported by the Segment SDK
  // logEventWithGroups: (eventName, eventProperties, groupTypesToGroupIds) => {},
});

export default segmentDestinationInterface;
