import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useUser } from 'reactfire';

import Loading from '../Loading';

function RequireLogin({ children }) {
  const { status, data: user } = useUser();

  useEffect(() => {
    if (status === 'loading') return;
    if (!user || !user?.email) {
      window.location.href = `/account/sign-in?next=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    }
  }, [status, user]);

  if (status === 'loading' || !user) {
    return (
      <Loading />
    );
  }

  return children;
}

RequireLogin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RequireLogin;
