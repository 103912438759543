import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  FirebaseAppProvider,
} from 'reactfire';

import theme from '../components/form/theme';
import firebaseConfig from './firebaseConfig';
import ReactFireProviders from './reactFireProvidersWrapper';

export function ErrorFallback() {
  return (
    <div className="error w-dyn-empty margin-bottom">
      <div>
        Something went wrong.
      </div>
    </div>
  );
}

export default function AppProviders({ noFirebase, children }) {
  if (noFirebase) {
    return (
      <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <ThemeProvider theme={theme}>
          <ReactFireProviders>
            {children}
          </ReactFireProviders>
        </ThemeProvider>
      </FirebaseAppProvider>
    </Sentry.ErrorBoundary>
  );
}

AppProviders.propTypes = {
  noFirebase: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
AppProviders.defaultProps = {
  noFirebase: false,
};
