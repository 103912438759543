import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function Button({
  label, onClick: handleClick, disabled, className,
}) {
  const [hovering, setHovering] = useState(false);
  const variants = {
    hover: { translateX: 0 },
    hidden: { translateX: '-101%' },
  };

  return (
    <AnimatePresence>
      <button
        onClick={handleClick}
        onMouseOver={() => setHovering(true)}
        onFocus={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onBlur={() => setHovering(false)}
        className={className || 'button-animated w-inline-block'}
        type="button"
        style={disabled ? { backgroundColor: '#151b22' } : {}}
        disabled={disabled}
      >
        <div className="button-label">
          {label}
        </div>
        {disabled ? null : (
          <motion.div
            className="button-hover-background"
            style={{ display: 'block' }}
            animate={hovering ? 'hover' : 'hidden'}
            variants={variants}
            initial="hidden"
            transition={{ type: 'tween' }}
          />
        )}
      </button>
    </AnimatePresence>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  className: '',
};

export default Button;
