import PropTypes from 'prop-types';
import React, { useState } from 'react';

import analytics from '../../app/analytics';
import Button from '../form/button';
import Calendly from './calendly';
import HubSpot from './hubSpot';

function CalendarButton({
  url, text, className, isCTA,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  return (
    <>
      { url.includes('calendly') ? (
        <Calendly url={url} isOpen={isOpen} close={close} />
      ) : null}
      { url.includes('hubspot') ? (
        <HubSpot url={url} isOpen={isOpen} close={close} />
      ) : null}
      <Button
        onClick={() => {
          if (isCTA) {
            analytics.clickCta({
              cta: text,
              url: window.location.href.replace(window.location.origin, ''),
            });
          }
          setIsOpen(true);
        }}
        label={text}
        className={className}
      />
    </>
  );
}

CalendarButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  isCTA: PropTypes.bool,
};

CalendarButton.defaultProps = {
  className: '',
  isCTA: false,
};

export { CalendarButton };
export default Calendly;
