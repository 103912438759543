import PropTypes from 'prop-types';
import React, { useState } from 'react';

import analytics from '../../app/analytics';
import Calendly from './calendly';
import HubSpot from './hubSpot';

export default function CalendarRawButton({
  url, text, isCTA, buttonInnerHtml, className,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  if (!buttonInnerHtml) return null;

  return (
    <>
      { url.includes('calendly') ? (
        <Calendly url={url} isOpen={isOpen} close={close} />
      ) : null}
      { url.includes('hubspot') ? (
        <HubSpot url={url} isOpen={isOpen} close={close} />
      ) : null}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        className={className}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
          if (isCTA) {
            analytics.clickCta({
              cta: text,
              url: window.location.href.replace(window.location.origin, ''),
            });
          }
        }}
        dangerouslySetInnerHTML={{ __html: buttonInnerHtml }} // eslint-disable-line react/no-danger
      />
    </>
  );
}

CalendarRawButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isCTA: PropTypes.bool,
  buttonInnerHtml: PropTypes.element.isRequired,
};

CalendarRawButton.defaultProps = {
  isCTA: false,
};
