/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select as MuiSelect,
} from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

function Select({
  children,
  label,
  name,
  value,
  error,
  helperText,
  fullWidth,
  onOpen: handleOpen,
  onClose: handleClose,
  onChange: handleChange,
  onValueChange: handleValueChange,
  setFieldValue,
  ...props
}) {
  const arrowControls = useAnimation();
  const arrow = () => (
    <motion.div
      style={{ marginRight: 24 }}
      className="accordion-arrow"
      animate={arrowControls}
    >
      navigate_next
    </motion.div>
  );

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      {label ? (
        <FormLabel
          sx={{
            color: 'text.main',
            '&.Mui-focused': {
              color: 'text.main',
            },
          }}
        >
          {label}
        </FormLabel>
      ) : null}
      <MuiSelect
        value={value}
        IconComponent={arrow}
        onOpen={(e) => {
          arrowControls.start({ rotateZ: 90 });
          handleOpen(e);
        }}
        onClose={(e) => {
          arrowControls.start({ rotateZ: 0 });
          handleClose(e);
        }}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          handleValueChange(e.target.value);
          handleChange(e);
        }}
        {...props}
      >
        {children}
      </MuiSelect>
      {helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Select.defaultProps = {
  label: null,
  error: false,
  fullWidth: false,
  helperText: null,
  onOpen: () => {},
  onClose: () => {},
  onChange: () => {},
  onValueChange: () => {},
};

export default Select;
