import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#4fafc3',
    },
    text: {
      main: '#c5cfd9',
      focused: '#acb8c4',
      disabled: '#bdbdbd',
    },
    secondary: {
      main: 'rgb(31, 42, 55)',
    },
    background: {
      main: 'rgb(21, 27, 34)',
      focused: 'rgb(31, 42, 55)',
    },
    border: {
      main: 'rgb(33, 38, 45)',
      focused: 'rgb(57, 66, 80)',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          input: {
            color: '#c5cfd9',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiInputBase-root': {
            backgroundColor: 'rgb(21, 27, 34)',
            border: '1px solid rgb(33, 38, 45)',
            borderRadius: '8px',
          },
          '& .MuiInputBase-root.Mui-focused': {
            color: '#acb8c4',
            backgroundColor: 'rgb(31, 42, 55)',
            border: '1px solid rgb(57, 66, 80)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#c5cfd9',
          backgroundColor: 'rgb(21, 27, 34)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#acb8c4',
          border: 1,
          backgroundColor: 'rgb(21, 27, 34)',
          borderColor: 'rgb(33, 38, 45)',
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.Mui-focused': {
            color: '#acb8c4',
            backgroundColor: 'rgb(31, 42, 55)',
            borderColor: 'rgb(57, 66, 80)',
            '.MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(21, 27, 34)',
          borderRadius: '8px',
          border: 1,
          '& h5': {
            fontSize: 16,
          },
          '& .MuiTableCell-root': {
            borderColor: 'rgb(33, 38, 45)',
            fontSize: '16px',
          },
          '& .MuiTableCell-head': {
            color: '#c5cfd9',
          },
          '& .MuiTableCell-body': {
            color: '#acb8c4',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: '#acb8c4',
          backgroundColor: 'rgb(21, 27, 34)',
          '& .MuiFilledInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px',
          },
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: 'rgb(31, 42, 55)',
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgb(21, 27, 34)',
          },
          '&.Mui-focused': {
            color: '#acb8c4',
            backgroundColor: 'rgb(31, 42, 55)',
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: 'rgb(57, 66, 80)',
            },
          },
          '& .MuiFilledInput-underline:before': {
            borderBottomColor: 'rgb(33, 38, 45)',
          },
          '& .MuiFilledInput-underline:hover:before': {
            borderBottomColor: '#acb8c4',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: 'text.main',
          border: 1,
          borderColor: 'border.main',
          '&.Mui-expanded': {
            backgroundColor: '#1f2a37',
          },
          '&:first-of-type': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          backgroundColor: 'rgb(21, 27, 34)',
          '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#4fafc3',
            '&:hover': {
              backgroundColor: '#4fafc3',
              color: '#ffffff',
            },
          },
          '&:hover': {
            backgroundColor: '#4fafc3',
            color: '#ffffff',
          },
        },
      },
    },
  },
});

export default theme;
