import { doc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import Loading from '../Loading';

const ViewPartContext = createContext();
export default function ViewPartProvider({ children }) {
  const params = new URLSearchParams(window.location.search);
  const partID = params.get('quote');

  const { data: part = {}, status: partQueryStatus } = useFirestoreDocData(doc(useFirestore(), 'parts', partID), { idField: 'id' });
  const { data: quote = {}, status: quoteQueryStatus } = useFirestoreDocData(doc(useFirestore(), 'quotes', part?.quote || 'nonexistent'), { idField: 'id' });

  const value = useMemo(() => ({
    partID,
    part,
    quote,
    expired: Date.now() - part.uploadedAt > 3 * 30 * 24 * 60 * 60 * 1000,
  }), [part, partID, quote]);

  if (partQueryStatus === 'loading' || quoteQueryStatus === 'loading') {
    return <Loading />;
  }

  return (
    <ViewPartContext.Provider value={value}>
      {children}
    </ViewPartContext.Provider>
  );
}
ViewPartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useViewPart = () => {
  const context = useContext(ViewPartContext);
  if (context === undefined) {
    throw new Error('useViewPart must be used within a ViewPartProvider');
  }
  return context;
};
