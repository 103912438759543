/* eslint-disable react/no-unknown-property */
import SwipeTwoToneIcon from '@mui/icons-material/SwipeTwoTone';
import {
  ArcballControls, Edges, Html, Outlines,
} from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import * as THREE from 'three';

function Component({
  attributes, material, index, brepFaces, outlineThickness,
}) {
  const positions = useMemo(
    () => new Float32Array(attributes.position.array),
    [attributes.position.array],
  );
  const normals = useMemo(() => {
    if (attributes.normal) {
      return new Float32Array(attributes.normal.array);
    }
    return [];
  }, [attributes.normal.array]);
  const indicies = useMemo(() => new Uint32Array(index.array), [index.array]);

  const { color, opacity } = material;

  return (
    <>
      {brepFaces.map((face) => {
        const faceIndicies = indicies.slice(face.first * 3, (face.last + 1) * 3);
        return (
          <mesh key={`${face.first}-${face.last}`}>
            <bufferGeometry attach="geometry">
              <bufferAttribute attach="index" count={faceIndicies.length} array={faceIndicies} itemSize={1} />
              <bufferAttribute attach="attributes-position" count={positions.length / 3} array={positions} itemSize={3} />
              {attributes.normal ? <bufferAttribute attach="attributes-normal" count={normals.length / 3} array={normals} itemSize={3} /> : null}
            </bufferGeometry>
            <meshBasicMaterial
              color={color}
              opacity={opacity}
              transparent={opacity !== 1.0}
              side={THREE.FrontSide}
            />
            <Edges threshold={45} />
            {outlineThickness > 0 ? <Outlines thickness={outlineThickness} color="black" /> : null}
          </mesh>
        );
      })}
    </>
  );
}

Component.propTypes = {
  attributes: PropTypes.shape({
    position: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
    normal: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  material: PropTypes.shape({
    color: PropTypes.string.isRequired,
    opacity: PropTypes.number,
  }).isRequired,
  index: PropTypes.shape({
    array: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  brepFaces: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  })).isRequired,
  outlineThickness: PropTypes.number,
};

Component.defaultProps = {
  outlineThickness: 0.0,
};

function Preview({
  meshes, resetIndex, touched,
}) {
  const { camera, size, controls } = useThree();
  const groupRef = React.useRef();

  const getCenterAndRadius = () => {
    const bbox = new THREE.Box3().setFromObject(groupRef.current);
    const center = bbox.getCenter(new THREE.Vector3());
    const radius = Math.max(...bbox.getSize(new THREE.Vector3()));
    return { center, radius };
  };

  const resetPart = () => {
    const isHorizontal = window.innerWidth > 479;

    controls.reset();

    const { center, radius } = getCenterAndRadius();

    camera.position.set(center.x, center.y - radius, center.z);
    camera.zoom = Math.max(size.width, size.height) / radius / 1.1;
    camera.lookAt(center);
    camera.up = new THREE.Vector3(isHorizontal ? 1 : 0, 0, isHorizontal ? 0 : 1);
    camera.updateProjectionMatrix();
  };

  // once we have controls in place, center part
  useEffect(() => {
    if (controls) { resetPart(); }
  }, [controls, resetIndex]);

  useFrame(({ clock }) => {
    if (!touched) {
      const isHorizontal = window.innerWidth > 479;
      const { center, radius } = getCenterAndRadius();
      const a = clock.getElapsedTime();
      camera.position.set(
        center.x + isHorizontal ? 0 : Math.sin(2 * a) * radius * 0.5,
        center.y - radius,
        center.z + isHorizontal ? Math.sin(2 * a) * radius * 0.5 : 0,
      );
      camera.zoom = Math.max(size.width, size.height) / radius / 1.1;
      camera.lookAt(center);
      camera.up = new THREE.Vector3(isHorizontal ? -1 : 0, 0, isHorizontal ? 0 : -1);
      camera.updateProjectionMatrix();
    }
  });

  const [zoom, setZoom] = useState(undefined);

  return (
    <>
      <group ref={groupRef}>
        {meshes.map((m) => {
          const {
            name, color, attributes, index,
          } = m;
          const material = {
            color: '#4FAFC3',
            opacity: 1.0,
          };
          if (color) {
            material.color = color.reduce((acc, c) => acc + Math.round(255.0 * c).toString(16).padStart(2, '0'), '#');
            if (name.toLowerCase().includes('cap')) {
              material.opacity = 0.3;
            }
          }
          return (
            <Component
              key={name}
              attributes={attributes}
              material={material}
              index={index}
              brepFaces={m.brep_faces}
              outlineThickness={name.toLowerCase().includes('connector') ? 0.05 * (9.0 / zoom) : 0.0}
            />
          );
        })}
      </group>
      {touched ? null : (
        <Html position={[0, -10, 0]} zIndexRange={[1, 0]} center>
          <SwipeTwoToneIcon fontSize="large" htmlColor="#0D1117" />
        </Html>
      )}
      <ArcballControls makeDefault onChange={() => { setZoom(camera.zoom); }} />
    </>
  );
}

Preview.propTypes = {
  meshes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    attributes: PropTypes.shape({
      position: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
      normal: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
    index: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  })).isRequired,
  resetIndex: PropTypes.number.isRequired,
  touched: PropTypes.bool,
};

Preview.defaultProps = {
  touched: false,
};

function Viewer({ meshes }) {
  const [touched, setTouched] = useState(false);
  const [resetIndex, setResetIndex] = useState(0);

  // handle the initial touch
  const touch = () => { setTouched(true); };
  const container = useRef(null);
  const containerRef = useCallback((node) => {
    if (container.current) {
      container.current.removeEventListener('pointerdown', touch);
    }

    if (node) {
      node.addEventListener('pointerdown', touch);
    }

    // Save a reference to the node
    container.current = node;
  }, []);

  // every time the meshes change, mark the viewer as untouched
  useEffect(() => {
    setTouched(false);
  }, [meshes]);

  useEffect(() => {
  // TODO: create a button for this
    window.resetCamera = () => {
      setResetIndex((i) => i + 1);
    };
  }, [setResetIndex]);

  return (
    <Canvas style={{ width: '100%', height: '100%' }} orthographic ref={containerRef}>
      <Preview
        meshes={meshes}
        resetIndex={resetIndex}
        touched={touched}
      />
    </Canvas>
  );
}

Viewer.propTypes = {
  meshes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    attributes: PropTypes.shape({
      position: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
      normal: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
    index: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  })).isRequired,
};

export default Viewer;
