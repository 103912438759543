import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';

import analytics from '../../app/analytics';

export default function HubSpot({ url: submittedURL, isOpen, close }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 'calc(100vh - 2em)',
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => { window.hbspt.meetings.create('.meetings-iframe-container'); }, 100);
    }
  }, [isOpen]);

  const url = useMemo(() => {
    const parsedURL = new URL(submittedURL);
    const embedded = parsedURL.searchParams.get('embed');
    if (!embedded) {
      parsedURL.searchParams.append('embed', true);
    }
    return parsedURL.href;
  }, [submittedURL]);

  const handleHubspotMessage = useCallback((event) => {
    if (event.isTrusted && event.data?.meetingBookSucceeded) {
      const email = event.data?.meetingsPayload?.bookingResponse?.postResponse?.contact?.email;
      const eventName = event.data?.meetingsPayload?.userSlug;
      analytics.meetingScheduled({
        userId_: 'not-used',
        eventName,
        email,
      });
      setTimeout(() => {
        close();
      }, 2000);
    }
  }, [close]);

  useEffect(() => {
    window.addEventListener('message', handleHubspotMessage);
    return () => {
      window.removeEventListener('message', handleHubspotMessage);
    };
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={close}
    >
      <Box sx={style}>
        <div className="meetings-iframe-container" data-src={url} style={{ minWidth: 400, minHeight: 400 }} />
      </Box>
    </Modal>
  );
}

HubSpot.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

HubSpot.defaultProps = {
  isOpen: false,
};
